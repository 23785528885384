import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import Error404View from "@/views/Error404View";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'Startseite' }
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "legal" */ '../views/ImpressumView'),
    meta: { title: 'Impressum' }
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "legal" */ '../views/DatenschutzView'),
    meta: { title: 'Datenschutz' }
  },
  {
    path: '/routen',
    name: 'Routen',
    component: () => import(/* webpackChunkName: "routs" */ '../views/RoutenView'),
    meta: { title: 'Routenliste' }
  },
  {
    path: '/kurse',
    name: 'Kurse',
    component: () => import(/* webpackChunkName: "general" */ '../views/KurseView'),
    meta: { title: 'Kurse' }
  },
  {
    path: '/preise',
    name: 'Preise',
    component: () => import(/* webpackChunkName: "general" */ '../views/PreiseView'),
    meta: { title: 'Eintritspreise' }
  },
  {
    path: '/halle',
    name: 'Halle',
    component: () => import(/* webpackChunkName: "general" */ '../views/HalleView'),
    meta: { title: 'Halle' }
  },
  {
    path: '/verein',
    name: 'Verein',
    component: () => import(/* webpackChunkName: "general" */ '../views/VereinView'),
    meta: { title: 'Verein' }
  },
  {
    path: '/kooperationen',
    name: 'Kooperationen',
    component: () => import(/* webpackChunkName: "general" */ '../views/KooperationenView'),
    meta: { title: 'Kooperationen' }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "general" */ '../views/KontaktView'),
    meta: { title: 'Kontakt' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "user" */ '../views/LoginView'),
    meta: { title: 'Login' }
  },
  {
    path: '/archiv',
    name: 'Archiv',
    component: () => import(/* webpackChunkName: "user" */ '../views/ArchivView'),
    meta: { title: 'Archiv' }
  },

  //Fehlerseite muss immer ganz unten sein
  {
    path: "/:catchAll(.*)",
    name: '404Error',
    component: Error404View,
    meta: { title: 'Seite nicht Gefunden', skeleton: false }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

//Zusätzliches Router Zeug
const DEFAULT_TITLE = 'Kletterhalle Seltmans';
router.afterEach((to, from) => {
    document.title = (to.meta.title + ' | ' + DEFAULT_TITLE )|| DEFAULT_TITLE;
});


export default router
