<template>
  <span class="icon">
    <FontAwesomeIcon :icon="icon" />
  </span>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'Icon',
  components: {FontAwesomeIcon},
  props: {
    icon : {
      type:[String, Array],
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
