<template>
  <footer class="footer has-background-dark has-text-white">
    <hr>
    <div class="level">
      <span class="level-left">
        © Alle Rechte vorbehalten, 1. Allgäuer Sportkletterclub e.V.
      </span>

      <div class="level-left">
        <Link to="Datenschutz">
          <span>Datenschutz</span>
        </Link> <span class="h" /> <Link to="Impressum">
          <span>Impressum</span>
        </Link>
      </div>
    </div>
  </footer>
</template>

<script>
import Link from '@/components/Elements/LinkForDarkBG'

export default {
  name: 'FooterPage',
  components: {Link}
}
</script>

<style scoped lang="sass">
.footer
  margin-top: 2em

.bottom
  position: relative
  bottom: 0

hr
  border: 1px solid #fcc930

.h
  min-width: 16px
</style>
