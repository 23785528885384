<template>
  <div class="section has-background-primary">
    <h2 class="is-size-3 has-text-centered has-text-black pad">
      News
    </h2>
    <div class="container pad">
      <div class="columns">
        <template v-for="index in 4" :key="index">
          <div class="column">
            <SozialMediaPost
              :message="get_fb_posts[index-1]['message']"
              :image-id="get_fb_posts[index-1]['images'][0]"
              :date="get_fb_posts[index-1]['date']"
            />
          </div>
        </template>
      </div>
    </div>
    <Link to="Archiv">
      <Button class="b">
        <span>zum Archiv</span> <IconArrowRight />
      </Button>
    </Link>
  </div>
</template>

<script>
import SozialMediaPost from "@/components/Elements/SozialMediaPost";
import IconArrowRight from "@/components/Elements/IconArrowRight";
import posts from "@/static_data/latest_posts.json";
import Button from "@/components/Elements/Button";
import Link from "@/components/Elements/LinkForYellowBG";

export default {
  name: "HomeView",
  components: {
    SozialMediaPost,
    Button,
    IconArrowRight,
    Link,
  },
  computed: {
    get_fb_posts: function () {
      for (let i = 0; i < posts.length; i++) {
        let post = posts[i]
        let date = new Date(post.date*1000);
        post.date = date.toLocaleString()
      }
      return posts;
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/sass/color"

.b
  float: right

.pad
  padding-bottom: 2em

</style>
