<template>
  <div class="Header is-block is-static">
    <nav class="level has-background-dark has-text-primary p-2 mb-0 is-size-6">
      <div class="level-item  is-align-content-space-evenly is-hidden-mobile">
        <Icon icon="fa-solid fa-paper-plane" />
        <div class="is-size-7 has-text-white pr-4">
          Heinrich-Nikolaus-Straße 15, 87480 Weitnau-Seltmans
        </div>
        <Icon icon="fa-solid fa-phone" />
        <div class="is-size-7 has-text-white pr-4">
          +49 8375 8219
        </div>
        <Icon icon="fa-solid fa-envelope" />
        <div class="is-size-7 has-text-white pr-4">
          info@kletterhalleseltmans.de
        </div>
      </div>

      <div class="level-right has-text-right-tablet has-text-centered-mobile">
        <SozialLink icon="fa-brands fa-instagram" url="https://www.instagram.com/kletterhalle_seltmans/" />
        <SozialLink icon="fa-brands fa-facebook-f" url="https://de-de.facebook.com/kletterhalleseltmans/" />
        <SozialLink icon="fa-brands fa-youtube" url="https://www.youtube.com/channel/UCPww0GXYX-pksol_kVEJ_sg" />
      </div>
    </nav>
    <nav class="level has-background-primary has-text-black pt-0 pr-2 pl-2 pb-4 is-size-5">
      <div class="level-left has-text-right" />

      <div class="level-item is-size-5 is-hidden-mobile">
        <Link to="/">
          <span>Startseite</span>
        </Link>
        <span class="h" />
        <a href="https://kletterhalleseltmans.myspreadshop.de/" class="red">
          <span>OnlineShop</span>
        </a>
        <span class="h" />
        <Link to="Routen">
          <span>Routenliste</span>
        </Link>
        <span class="h" />
        <Link to="Kurse">
          <span>Kurse</span>
        </Link>
        <span class="h" />
        <Link to="Preise">
          <span>Eintrittspreise</span>
        </Link>
        <span class="h" />
        <Link to="Halle">
          <span>Halle</span>
        </Link>
        <span class="h" />
        <Link to="Verein">
          <span>Verein</span>
        </Link>
        <span class="h" />
        <Link to="Kooperationen">
          <span>Kooperationen</span>
        </Link>
        <span class="h" />
        <span class="h" />
        <Link to="Kontakt">
          <span>Kontakt</span>
        </Link>
      </div>

      <div class="level-right has-text-right is-hidden-tablet">
        <Icon class="mobilmen" icon="fa-solid fa-bars" @click="mobileModal = true" />
      </div>

      <div class="level-right has-text-left is-hidden-mobile">
        <Link to="Login">
          <span>Anmelden</span>
        </Link>
      </div>

      <div v-if="mobileModal" class="modal is-active" @click="mobileModal = false">
        <div class="modal-background" />
        <div class="modal-content" />
        <div class="modal-card is-centered has-text-centered">
          <LinkForDarkBG to="/">
            <span>Startseite</span>
          </LinkForDarkBG>
          <br>
          <a href="https://kletterhalleseltmans.myspreadshop.de/" class="red">
            <span>OnlineShop</span>
          </a>
          <br>
          <LinkForDarkBG to="Routen">
            <span>Routenliste</span>
          </LinkForDarkBG>
          <br>
          <LinkForDarkBG to="Kurse">
            <span>Kurse</span>
          </LinkForDarkBG>
          <br>
          <LinkForDarkBG to="Preise">
            <span>Eintrittspreise</span>
          </LinkForDarkBG>
          <br>
          <LinkForDarkBG to="Halle">
            <span>Halle</span>
          </LinkForDarkBG>
          <br>
          <LinkForDarkBG to="Verein">
            <span>Verein</span>
          </LinkForDarkBG>
          <br>
          <LinkForDarkBG to="Kooperationen">
            <span>Kooperationen</span>
          </LinkForDarkBG>
          <br>
          <br>
          <LinkForDarkBG to="Kontakt">
            <span>Kontakt</span>
          </LinkForDarkBG>
        </div>
        <div class="modal-card botton has-text-centered">
          <LinkForDarkBG to="Login">
            <span>Anmelden</span>
          </LinkForDarkBG>
        </div>

        <button class="modal-close is-large" aria-label="close" @click="mobileModal = false" />
      </div>
    </nav>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane, faPhone, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons'
import {faFacebookF, faYoutube, faInstagram} from  '@fortawesome/free-brands-svg-icons'
import Icon from '@/components/Elements/Icon'
import SozialLink from '@/components/PageMainSkeleton/HeaderPage/SozialLink'
import Link from "@/components/Elements/LinkForYellowBG";
import LinkForDarkBG from "@/components/Elements/LinkForDarkBG";

export default {
  name: 'HeaderPage',
  components: {Icon, SozialLink, Link, LinkForDarkBG},
  data(){
    return {
      mobileModal: false
    }
  },
  beforeMount () {
    library.add(faPaperPlane)
    library.add(faPhone)
    library.add(faEnvelope)
    library.add(faFacebookF)
    library.add(faYoutube)
    library.add(faInstagram)
    library.add(faBars)
  }
}
</script>

<style scoped lang="sass">
.h
  min-width: 8px
  width: 0.2em
  padding: 0px 8px 0px 8px

.mobilmen
  background-color: white
  border-radius: 64px
  min-width: 32px
  min-height: 32px

.mobilmen:hover
  color: #fcc930

.botton
  position: fixed
  bottom: 2em

.modal
  touch-action: none

.red
  color: red

</style>
