<template>
  <Icon icon="fa-solid fa-arrow-right" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Icon from '@/components/Elements/Icon'

export default {
  name: 'IconArrowRight',
  components: {Icon},
  beforeMount () {
    library.add(faArrowRight)
  }
}
</script>

<style scoped>

</style>
