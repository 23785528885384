<template>
  <div class="home">
    <IntroSection />

    <Edge />

    <NewsSection />

    <OpeningSection />

    <CoursesSection />

    <SponsorSection />
  </div>
</template>

<script>
import Edge from "@/components/Elements/Edge";
import IntroSection from "@/components/HomeView/IntroSection.vue";
import NewsSection from "@/components/HomeView/NewsSection";
import OpeningSection from "@/components/HomeView/OpeningSection.vue";
import CoursesSection from "@/components/HomeView/CoursesSection.vue";
import SponsorSection from "@/components/KooperationeView/SponsorSection.vue";

export default {
  name: "HomeView",
  components: {
    Edge,
    NewsSection,
    IntroSection,
    CoursesSection,
    OpeningSection,
    SponsorSection,
  },
};
</script>

<style scoped lang="sass">
@import "@/sass/color"
</style>
