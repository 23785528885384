<template>
  <div class="ErrorPage">
    <div class="container has-text-centered">
      <img src="../assets/background/bungee.svg">
      <h1 class="is-size-2">
        404
      </h1>

      <h2 class="is-size-4">
        Es tut uns leid das du aus der Route gefolgen bist!
      </h2>
      <br>
      <Button @click="$router.push('/')">
        <span>Probiere es noch mal</span><IconArrowRight />
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Elements/Button";
import IconArrowRight from "@/components/Elements/IconArrowRight";

export default {
  name: "404View",
  components: {Button, IconArrowRight}
}
</script>

<style scoped lang="sass">
img
  max-height: 50vh
</style>
