<template>
  <a :href="url" target="_blank" class="social-link">
    <Icon :icon="icon" />
  </a>
</template>

<script>
import Icon from '@/components/Elements/Icon'

export default {
  name: 'SozialLink',
  components:{Icon},
  props: {
    icon: {
      type: [String, Array],
      default: ''
    },
    url : {
      type: [String],
      default: ''
    }
  }
}
</script>

<style scoped lang="sass">

@import "@/sass/color"

.social-link
  color: white

.social-link:hover
  color: $primary

</style>
