<template>
  <div class="MaskContent" :class="c">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MaskContent',
  data () {
    return{
      c:'m0'
    }
  },
  created () {
    this.c = 'm' + Math.floor(Math.random() * 8)
  }
}
</script>

<style scoped lang="sass">

.MaskContent
  mask-position: center
  mask-repeat: no-repeat
  mask-size: 100%
  max-width: 1600px
  max-height: 900px

.m0
  mask-image: url("@/assets/style/mask.png")

.m1
  mask-image: url("@/assets/style/maske1.png")

.m2
  mask-image: url("@/assets/style/maske2.png")

.m3
  mask-image: url("@/assets/style/maske3.png")

.m4
  mask-image: url("@/assets/style/maske4.png")

.m5
  mask-image: url("@/assets/style/maske5.png")

.m6
  mask-image: url("@/assets/style/maske6.png")

.m7
  mask-image: url("@/assets/style/maske7.png")

.m8
  mask-image: url("@/assets/style/maske8.png")

</style>
