<template>
  <div class="section has-text-centered">
    <OpeningDynamic class="is-hidden-tablet pad" />
    <OpeningStatic class="is-hidden-mobile pad" />
  </div>
</template>

<script>
import OpeningDynamic from "@/components/Elements/OpeningTimes/OpeningDynamic";
import OpeningStatic from "@/components/Elements/OpeningTimes/OpeningStatic";

export default {
  name: "OpeningSection",
  components: {
    OpeningDynamic,
    OpeningStatic,
  },
};
</script>

<style scoped lang="sass">
@import "@/sass/color"
</style>
