<template>
  <div @click.prevent.capture="OnDataClick">
    <div class="card" style="width: 300px">
      <div class="card-image">
        <figure class="image">
          <img
            :src="'/fimg/' + imageId + '.jpg'"
            style="height: 300px; width: 300px; object-fit: fill"
          >
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <img
                src="@/assets/profiles/Sebastian_Prause.jpg"
                alt="Profile Picture"
              >
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-4">
              {{ GetTitle }}
            </p>
            <p class="subtitle is-6">
              @ Sebastian Prause
            </p>
            <p class="subtitle is-7">
              {{ date }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <ModalCard v-model:showValue="modal" :title="GetTitle">
      <div class="content">
        {{ message }}
      </div>
    </ModalCard>
  </div>
</template>

<script>
import ModalCard from "@/components/Elements/ModalCard";
export default {
  name: "SozialMediaPost",
  components: { ModalCard },
  props: {
    message: {
      type: [String],
      default: "",
    },
    imageId: {
      type: [String],
      default: "",
    },
    date: {
      type: [String],
      default: "",
    }
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    GetTitle: function () {
      return this.message.split("\n")[0].replace(/\p{Emoji}/gu, "");
    },
  },
  methods: {
    OnDataClick: function () {
      this.modal = true;
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/sass/color"

.sozial:hover
  background-color: $dark
  cursor: pointer

.content
  white-space: pre-line

.sozial .mw
  max-height: 443px
</style>
