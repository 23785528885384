<template>
  <div class="SponsorLogoLnk has-text-centered">
    <a :href="url" target="_blank"><img :style="{'max-height':height}" :src="img"></a>
  </div>
</template>

<script>
export default {
  name: 'SponsorLogoLink',
  props:  {
    url : {
      type:[String],
      default: ''
    },
    img : {
      type:[URL],
      default: ''
    },
    height: {
      type:[String],
      default: 'auto'
    }
  }
}
</script>

<style scoped lang="sass">
.SponsorLogoLnk
  box-sizing: inherit

img:hover
  animation: bounce 0.5s

//Aus Animate.css Source Übernommen
//https://animate.style/
//https://github.com/animate-css/animate.css/blob/main/source/attention_seekers/bounce.css
@keyframes bounce
  from,
  20%,
  53%,
  to
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    transform: translate3d(0, 0, 0)

  40%,
  43%
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06)
    transform: translate3d(0, -30px, 0) scaleY(1.1)

  70%
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06)
    transform: translate3d(0, -15px, 0) scaleY(1.05)

  80%
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    transform: translate3d(0, 0, 0) scaleY(0.95)

  90%
    transform: translate3d(0, -4px, 0) scaleY(1.02)

.bounce
  animation-name: bounce
  transform-origin: center bottom


</style>
