<template>
  <div class="container has-text-centered has-text-black">
    <h2 class="is-size-3 pad">
      Sponsoren
    </h2>
    <div class="columns">
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/benky.png')"
          url="https://benkyclimbing.com/"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/ct.png')"
          url="https://www.climbingtechnology.com/de"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/devold.svg')"
          url="https://www.devold.com/de-de/"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/Klettershop.jpg')"
          url="https://www.klettershop.de/"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/lowa.svg')"
          url="https://www.lowa.de/"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/rapunzel.png')"
          url="https://www.rapunzel.de/"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/sport-frey.jpg')"
          url="https://sportfrey.de/"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/tendon-invertet.svg')"
          url="https://www.mytendon.com"
        />
      </div>
      <div class="column">
        <SponsorLogoLink
          :img="require('@/assets/sponsors/volx-invertet.png')"
          url="https://volxholds.com/"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SponsorLogoLink from "@/components/Elements/SponsorLogoLink";

export default {
  name: "SponsorSection",
  components: {
    SponsorLogoLink,
  },
};
</script>

<style scoped lang="sass">
@import "@/sass/color"

.pad
  padding-bottom: 2em
</style>
