<template>
  <div class="parallax-container2 has-text-centered has-text-white pad">
    <h2 class="is-size-3 pad">
      Kursangebote
    </h2>
    <div class="container">
      <div class="columns">
        <div class="column">
          <MaskContent class="kurs">
            <img class="kurs-img" src="@/assets/icons/raw/climbing.png" width="200">
          </MaskContent>
          <Link to="Kurse">
            <Button><span>Zu den Boulderkursen</span> <IconArrowRight /></Button>
          </Link>
        </div>
        <div class="column">
          <MaskContent class="kurs">
            <img
              class="kurs-img"
              src="@/assets/icons/raw/climbing-silhouette.png"
              width="200"
            >
          </MaskContent>
          <Link to="Kurse">
            <Button><span>Zu den Kletterkursen</span> <IconArrowRight /></Button>
          </Link>
        </div>
        <div class="column">
          <MaskContent class="kurs">
            <img class="kurs-img" src="@/assets/icons/raw/teacher.png" width="200">
          </MaskContent>
          <Link to="Kurse">
            <Button><span>Zu den Theoriekursen</span> <IconArrowRight /></Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaskContent from "@/components/Elements/MaskContent";
import Button from "@/components/Elements/Button";
import IconArrowRight from "@/components/Elements/IconArrowRight";
import Link from "@/components/Elements/LinkForYellowBG";

export default {
  name: "CoursesSection",
  components: {
    MaskContent,
    Button,
    IconArrowRight,
    Link,
  },
};
</script>

<style scoped lang="sass">
@import "@/sass/color"

.parallax-container2
  background-image: url("@/assets/background/background-2.jpg")
  background-attachment: fixed
  background-position: center
  background-size: cover
  min-height: 25vh

.pad
  padding-bottom: 2em


.kurs
  min-height: 256px
  background-color: white
  color: black

.kurs-img
  height: 200px
  padding-top: 12%
</style>
