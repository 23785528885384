<template>
  <div v-if="showValue" class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ title }}
        </p>
        <button class="delete" aria-label="close" @click="OnCloseButton" />
      </header>
      <section class="modal-card-body has-text-black">
        <slot />
      </section>
      <footer v-if="hasFooter" class="modal-card-foot">
        <button class="button is-success">
          Save changes
        </button>
        <button class="button" @click="OnCloseButton">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalCard',
  props: {
    showValue : {
      type: Boolean,
      default: false
    },
    //TODO Improve Footer Handling
    hasFooter: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['update:showValue'],
  methods: {
    OnCloseButton : function (){
      this.$emit('update:showValue', false)
    }
  }
}
</script>

<style scoped>

</style>
