<template>
  <button class="button my-primary">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button'
}
</script>

<style scoped lang="sass">
@import "@/sass/color"

.my-primary
  background-color: $primary
  color: $dark
  transition: background-color 500ms ease-out 100ms, color 500ms ease-out 100ms

.my-primary:hover
  background-color: $dark
  color: white

</style>
