<template>
  <HeaderPage v-if="GetRouteSkeleton" />
  <slot />
  <FooterPage v-if="GetRouteSkeleton" />
</template>

<script>
import HeaderPage from '@/components/PageMainSkeleton/HeaderPage'
import FooterPage from '@/components/PageMainSkeleton/FooterPage'

export default {
  name: 'PageMainSekeleton',
  components: { HeaderPage, FooterPage },
  computed: {
    GetRouteSkeleton: function () {
      if (this.$route.meta['skeleton'] === undefined){
        return true
      }
      return this.$route.meta['skeleton']
    }
  }
}
</script>

<style scoped>

</style>
