<template>
  <div class="edge primary  is-hidden-mobile" />
</template>

<script>
//TODO ggf Implement Color Mechanismus

export default {
  name: 'Edge'
}
</script>

<style scoped lang="sass">
@import "@/sass/color"

.edge
  margin-top: -100px
  height: 0
  border-style: solid
  border-width: 0 0 100px 100vw

.primary
  border-color: transparent $primary $primary transparent
</style>
