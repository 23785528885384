<template>
  <router-link class="color" :to="to">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'LinkForYellowBG',
  props: {
    to : {
      type:[String],
      default: ''
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/sass/color"

.color
  color: black

.color:hover
  color: $dark

</style>
