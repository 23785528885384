<template>
  <PageMainSekeleton>
    <router-view />
  </PageMainSekeleton>
</template>

<script>
import PageMainSekeleton from '@/components/PageMainSekeleton'

export default {
  name: 'AppMain',
  components: { PageMainSekeleton }
}

</script>

<style lang="scss">

</style>
