<template>
  <div class="parallax-container">
    <div class="container">
      <div class="columns">
        <div class="column">
          <a href="https://kletterhalleseltmans.de/Jubilaeum"><img src="@/assets/logo/logo-horizontal-dunkel.png"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "IntroSection",
  components: {},
};
</script>

<style scoped lang="sass">
@import "@/sass/color"

.parallax-container

  background-image: url("@/assets/background/climber.jpeg")
  background-attachment: fixed
  background-position: center
  background-size: cover
  min-height: 25vh
</style>
