<template>
  <table class="table">
    <tr v-for="(item, key, index) in times" :key="index">
      <td class="has-text-right">
        {{ key }}
      </td>
      <td class="has-text-left">
        {{ item }}
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'OpeningTimeTable',
  props: {
    times: {
      type: [Object],
      required: true
    }
  },
}
</script>

<style scoped>
.table {
  margin-left: auto;
  margin-right: auto;
  border-bottom: black;
}
</style>
